import { get, isEmpty, isUndefined, omitBy } from 'lodash';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import React from 'react';

import { getFeatureFlags } from '@utils/feature-flags';
import { assetQuery, categoriesQuery, floodlightQuery, seomaticQuery } from '../../content/query-snippets';
import { categoriesPropType, cmsProductsPropTypes, floodlightPageLoadEventPropTypes } from '../../prop-types';
import { ContentMatrix } from '../../components/content-matrix';
import { decals } from '../../components/decal/constants';
import { extractSeoMetaFromEntry, seoPropDefaults, seoPropTypes } from '../../utils/extract-seo-meta-from-entry';
import { FeatureCard } from '../../components/card/feature-card';
import { fetch } from '../../content/queries';
import { FilterGrid } from '../../components/filter-grid';
import { Layout } from '../../components/layout';
import { logger } from '../../utils/logger';
import { navigation } from '../../constants';
import { PageHeaderBlock } from '../../components/content-image';
import { setCacheHeaders } from '../../utils/set-cache-headers';
import Error from '../_error';

const DEFAULT_FILTER = 'All Products';

const ProductsPage = ({ products, filters, contentMatrix, statusCode, metaContent, floodlightPageLoadEvent }) => {
  if (statusCode) {
    return <Error statusCode={statusCode} />;
  }
  return (
    <Layout title="Nando’s At Home" metaContent={metaContent} floodlightPageLoadEvent={floodlightPageLoadEvent}>
      <PageHeaderBlock
        heading="PERi-PERi Products"
        subheading="We’ve created a range of products so you can splash, drizzle, squeeze and sprinkle to your heart’s content."
        leftDecal={decals['peri-products'].left}
        rightDecal={decals['peri-products'].right}
      />
      <FilterGrid items={products} filters={filters} defaultFilter={DEFAULT_FILTER} cardComponent={FeatureCard} />
      <ContentMatrix blocks={contentMatrix} />
    </Layout>
  );
};

ProductsPage.getInitialProps = async ({ query, res }) => {
  const preview = get(query, 'x-craft-live-preview');
  const token = get(query, 'token');
  const slug = 'peri-products';
  const featureFlags = await getFeatureFlags();

  const contentQuery = gql`
    query getContentFromCMS {
      content: entry(siteId: "${process.env.cms.siteId}", slug: "${slug}") {
        ... on pages_pages_Entry {
          ${ContentMatrix.query}
          ${floodlightQuery}
        }
      }
      products: entries(section: "products", siteId: "${process.env.cms.siteId}") {
        ... on products_product_Entry {
          ${assetQuery('gridImage')}
          productCategory {
            title
          }
          slug
          title
        }
      }
      categories: ${categoriesQuery('productCategories')}
      ${seomaticQuery(slug)}
    }
  `;

  try {
    const data = await fetch({
      query: contentQuery,
      headers: omitBy({ 'x-craft-live-preview': preview, token }, isUndefined),
    });

    const contentMatrix = get(data, 'content.contentMatrix', []);
    const entries = get(data, 'products', []);
    const categories = get(data, 'categories', []);
    const floodlightPageLoadEvent = get(data, 'content.floodlightPageLoadEvent[0]');
    const metaContent = extractSeoMetaFromEntry(data);

    const products = entries.map(({ productCategory, gridImage, title, ...rest }) => ({
      category: get(productCategory, '[0].title'),
      href: `${navigation.PRODUCTS}/${rest.slug}`,
      image: gridImage,
      heading: title,
      ...rest,
    }));
    const mappedCategories = categories.map(({ title }) => title);

    const filters = [DEFAULT_FILTER, ...mappedCategories];

    if (res) {
      if (isEmpty(data)) {
        // eslint-disable-next-line no-param-reassign
        res.statusCode = 404;
        return { statusCode: 404, featureFlags };
      }

      const isPreview = preview && token;
      setCacheHeaders({ res, isPreview });
    }

    return {
      contentMatrix,
      filters,
      floodlightPageLoadEvent,
      metaContent,
      products,
      featureFlags,
    };
  } catch (err) {
    logger.error('CMS: Failed to fetch product from CMS', err);
    return { statusCode: 500, featureFlags };
  }
};

ProductsPage.propTypes = {
  contentMatrix: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: categoriesPropType.isRequired,
  floodlightPageLoadEvent: floodlightPageLoadEventPropTypes,
  metaContent: PropTypes.shape(seoPropTypes),
  products: cmsProductsPropTypes.isRequired,
  statusCode: PropTypes.number,
};

ProductsPage.defaultProps = {
  floodlightPageLoadEvent: undefined,
  metaContent: seoPropDefaults,
  statusCode: null,
};

export default ProductsPage;
